import React from "react";
import theme from "theme";
import { Theme, Text, Link, Image, LinkBox, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"blog"} />
		<Helmet>
			<title>
				Sikeres Kommunikáció - Legolvasottabb Cikkjeink
			</title>
			<meta name={"description"} content={"Ismerd fel a másokban mélyen rejlő érzelmeket, elrejtett félelmeket, bizonytalanságokat és igényeket, mindezt akár hat percen belül."} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
		</Helmet>
		<Components.GlobalHeaderNavBar overflow-x="hidden" overflow-y="hidden" />
		<Section padding="90px 0 50px 0" quarkly-title="News/Streams/Blog-6" overflow-x="hidden" overflow-y="hidden">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" text-align="center">
				Legolvasottabb Cikkjeink
			</Text>
			<Link
				href="https://blog.sziklaybence.com/"
				padding="12px 24px 12px 24px"
				color="--brandLight"
				background="--color-brandCta"
				text-decoration-line="initial"
				font="--lead"
				border-radius="8px"
				margin="0px 0 0px 0px"
				transition="background-color 0.2s ease-in-out 0s"
				hover-background="--color-orange"
				sm-margin="0px 0px 16px 0px"
				sm-text-align="center"
				sm-width="60%"
				hover-transition="background-color 0.2s ease-in-out 0s"
				text-align="center"
				width="fit-content"
				max-width="185px"
				align-self="center"
				target="_blank"
			>
				Tovább a Blogra
			</Link>
			<Text
				margin="0px 0 60px 0px"
				font="--base"
				color="#626970"
				text-align="center"
				width="fit-content"
				align-self="center"
				min-width="100%"
			>
				blog.sziklaybence.com
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 40px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					margin="0px 0px 0 0px"
					md-flex-direction="column"
					flex-direction="column"
					justify-content="space-between"
					sm-justify-content="flex-start"
				>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0 0px"
						display="flex"
						flex-direction="column"
						md-width="100%"
						md-justify-content="flex-start"
					>
						<Image
							src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/A-viselkedes-periodusos-rendszere-4.1-1a-760x595.png?v=2023-11-27T14:04:24.537Z"
							object-fit="cover"
							margin="0px 0 30px 0px"
							md-width="100%"
							md-margin="0px 50px 35px 0px"
							display="block"
							border-radius="25px"
							height="350px"
							srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/A-viselkedes-periodusos-rendszere-4.1-1a-760x595.png?v=2023-11-27T14%3A04%3A24.537Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/A-viselkedes-periodusos-rendszere-4.1-1a-760x595.png?v=2023-11-27T14%3A04%3A24.537Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/A-viselkedes-periodusos-rendszere-4.1-1a-760x595.png?v=2023-11-27T14%3A04%3A24.537Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/A-viselkedes-periodusos-rendszere-4.1-1a-760x595.png?v=2023-11-27T14%3A04%3A24.537Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/A-viselkedes-periodusos-rendszere-4.1-1a-760x595.png?v=2023-11-27T14%3A04%3A24.537Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/A-viselkedes-periodusos-rendszere-4.1-1a-760x595.png?v=2023-11-27T14%3A04%3A24.537Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/A-viselkedes-periodusos-rendszere-4.1-1a-760x595.png?v=2023-11-27T14%3A04%3A24.537Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
						<Box min-width="100px" min-height="100px">
							<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="https://blog.sziklaybence.com/a-viselkedes-periodusos-rendszere/" target="_blank">
								<Text margin="0px 0px 0 0px" font="--h2Small" color="--brandPrimary">
									A Viselkedés Periódusos Rendszere
								</Text>
							</LinkBox>
							<Box
								min-width="10px"
								min-height="10px"
								display="flex"
								align-items="center"
								margin="0px 0px 10px 0px"
							>
								<Link
									href="https://blog.sziklaybence.com/viselkedes-periodusos-rendszere/"
									margin="0px 0px 10px 0px"
									font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
									color="#626970"
									padding="3px 10px 3px 10px"
									background="--color-lightD1"
									border-radius="15px"
									text-decoration="none"
									target="_blank"
								>
									Viselkedés Periódusos Rendszere
								</Link>
							</Box>
							<Text
								margin="0px 0px 20px 0px"
								font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
								lg-margin="0px 0px 40px 0px"
								color="#626970"
								md-margin="0px 0px 25px 0px"
							>
								A Viselkedési Elemek Periódusos Rendszere bármilyen szituációban és helyzetben működik, bármely országban. Kiképzési eszközként, a táblázat egy felbecsülhetetlen segéd, amely segít elsajátítani azt a gyors, pontos és mérhető tudást, mellyel bárkit úgy tudsz olvasni akár egy könyvet. Elemző eszközként, a történelemben először, minden interakció lebontható pontos és általánosan definiálható gesztusokra, viselkedésekre, megtévesztésekre és szóbeli...
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					margin="0px 0px 0 0px"
					md-flex-direction="column"
					flex-direction="column"
					justify-content="space-between"
					sm-justify-content="flex-start"
				>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0 0px"
						display="flex"
						flex-direction="column"
						md-width="100%"
					>
						<Image
							src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/business-man-sits-polygraph-1024x536.jpg?v=2023-11-27T14:57:09.166Z"
							display="block"
							object-fit="cover"
							border-radius="25px"
							margin="0px 0 30px 0px"
							height="350px"
							md-width="100%"
							md-margin="0px 50px 35px 0px"
							srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/business-man-sits-polygraph-1024x536.jpg?v=2023-11-27T14%3A57%3A09.166Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/business-man-sits-polygraph-1024x536.jpg?v=2023-11-27T14%3A57%3A09.166Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/business-man-sits-polygraph-1024x536.jpg?v=2023-11-27T14%3A57%3A09.166Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/business-man-sits-polygraph-1024x536.jpg?v=2023-11-27T14%3A57%3A09.166Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/business-man-sits-polygraph-1024x536.jpg?v=2023-11-27T14%3A57%3A09.166Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/business-man-sits-polygraph-1024x536.jpg?v=2023-11-27T14%3A57%3A09.166Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/business-man-sits-polygraph-1024x536.jpg?v=2023-11-27T14%3A57%3A09.166Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
						<Box min-width="100px" min-height="100px">
							<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="https://blog.sziklaybence.com/poligrafhoz-titkok/" target="_blank">
								<Text margin="0px 0px 0 0px" font="--h2Small" color="--brandPrimary">
									Egy viselkedés-profilozó feltárja a poligráfhoz kapcsolódó titkokat
								</Text>
							</LinkBox>
							<Box
								min-width="10px"
								min-height="10px"
								display="flex"
								align-items="center"
								margin="0px 0px 10px 0px"
							>
								<Link
									href="https://blog.sziklaybence.com/testbeszed/"
									margin="0px 0px 10px 0px"
									font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
									color="#626970"
									padding="3px 10px 3px 10px"
									background="--color-lightD1"
									border-radius="15px"
									text-decoration="none"
									target="_blank"
								>
									Testbeszéd
								</Link>
							</Box>
							<Text
								margin="0px 0px 20px 0px"
								font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
								lg-margin="0px 0px 40px 0px"
								color="#626970"
								md-margin="0px 0px 25px 0px"
							>
								Ne a poligráftól, hanem a kihallgató tapasztaltságának fokától kell tartani. A poligráfoknak és a kihallgatásoknak van egy közös jellemvonása: a megtévesztés és a különféle trükkök, a csalás bevetése. A legtöbb ember azért bukik meg, mert túl sokat mond. Nem azért, mert a poligráf megtévesztésre utaló jeleket detektált volna. Nem létezik olyan, hogy hazugságvizsgáló gép, soha nem is létezett. Egy tapasztalt…
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					margin="0px 0px 0 0px"
					md-flex-direction="column"
					flex-direction="column"
					justify-content="space-between"
					sm-justify-content="flex-start"
				>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0 0px"
						display="flex"
						flex-direction="column"
						md-width="100%"
						sm-margin="0px 0px 0 0px"
					>
						<Image
							src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/hazugsag-1.jpg?v=2023-11-27T15:05:55.923Z"
							display="block"
							object-fit="cover"
							border-radius="25px"
							margin="0px 0 30px 0px"
							height="350px"
							md-width="100%"
							md-margin="0px 50px 35px 0px"
							srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/hazugsag-1.jpg?v=2023-11-27T15%3A05%3A55.923Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/hazugsag-1.jpg?v=2023-11-27T15%3A05%3A55.923Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/hazugsag-1.jpg?v=2023-11-27T15%3A05%3A55.923Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/hazugsag-1.jpg?v=2023-11-27T15%3A05%3A55.923Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/hazugsag-1.jpg?v=2023-11-27T15%3A05%3A55.923Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/hazugsag-1.jpg?v=2023-11-27T15%3A05%3A55.923Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/hazugsag-1.jpg?v=2023-11-27T15%3A05%3A55.923Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
						<Box min-width="100px" min-height="100px">
							<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="https://blog.sziklaybence.com/mikro-arckifejezesek-es-a-hazugsag/" target="_blank">
								<Text margin="0px 0px 0 0px" font="--h2Small" color="--brandPrimary">
									Mikro-arckifejezések és a hazugság
								</Text>
							</LinkBox>
							<Box
								min-width="10px"
								min-height="10px"
								display="flex"
								align-items="center"
								margin="0px 0px 10px 0px"
							>
								<Link
									href="https://blog.sziklaybence.com/testbeszed/"
									margin="0px 0px 10px 0px"
									font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
									color="#626970"
									padding="3px 10px 3px 10px"
									background="--color-lightD1"
									border-radius="15px"
									text-decoration="none"
									target="_blank"
								>
									Testbeszéd
								</Link>
							</Box>
							<Text
								margin="0px 0px 20px 0px"
								font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
								lg-margin="0px 0px 40px 0px"
								color="#626970"
								md-margin="0px 0px 25px 0px"
							>
								Ahhoz, hogy észrevegyünk egy mikro-arckifejezést, számítógép-sebességgel kell feldolgoznunk az arc mozdulatait, minden más testbeszédjelet figyelmen kívül hagyva. Olyan gyors mozdulatokról van szó, amelyek az arcon a másodperc körülbelül huszonötöd része alatt suhannak át. A mikro-arckifejezések elemzése nemcsak sok gyakorlást igényel, de a világ legismertebb szakértői is csak...
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section overflow-x="hidden" overflow-y="hidden">
			<Link
				href="https://blog.sziklaybence.com/"
				padding="12px 24px 12px 24px"
				color="--brandLight"
				background="--color-brandCta"
				text-decoration-line="initial"
				font="--lead"
				border-radius="8px"
				margin="0px 0 0px 0px"
				transition="background-color 0.2s ease-in-out 0s"
				hover-background="--color-orange"
				sm-margin="0px 0px 16px 0px"
				sm-text-align="center"
				sm-width="60%"
				hover-transition="background-color 0.2s ease-in-out 0s"
				text-align="center"
				width="fit-content"
				align-self="center"
				max-width="185px"
				target="_blank"
			>
				Tovább a Blogra
			</Link>
			<Text
				margin="0px 0 60px 0px"
				font="--base"
				color="#626970"
				width="fit-content"
				align-self="center"
				min-width="100%"
				text-align="center"
			>
				blog.sziklaybence.com
			</Text>
		</Section>
		<Components.FooterGlobal overflow-x="hidden" overflow-y="hidden">
			<Override slot="text1">
				Legyen szó kérdésről, megjegyzésről vagy javaslatról, mindig örömmel fogadjuk megkeresésedet.{" "}
				<Link
					href="../about/#contact"
					color="--brandSecondary"
					text-decoration-line="initial"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Írj nekünk itt.
				</Link>
			</Override>
		</Components.FooterGlobal>
		<Components.QuarklycommunityKitBackToTop overflow-x="hidden" overflow-y="hidden">
			<Override slot="Icon" color="--brandCta" />
		</Components.QuarklycommunityKitBackToTop>
	</Theme>;
});